import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UserInfo from './UserInfo';
import ChatInterface from './ChatInterface';
import '../App.css';

const MainComponent = () => {
    const { assistantName, profileName } = useParams();
    const decodedProfileName = profileName.replace(/_/g, ' ');
    const [assistantId, setAssistantId] = useState(null);
    const [user, setUser] = useState({});
    const [profile, setProfile] = useState({});
  
    useEffect(() => {
      const fetchAssistantId = async () => {
        try {
          const response = await fetch(`https://capture-wetiwctgqq-nn.a.run.app/v1/firestore/get_asst_id_by_name?assistant_name=${assistantName}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          let data = await response.text();
          data = data.replace(/^"|"$/g, '');
          setAssistantId(data);
        } catch (error) {
          console.error("There was an error fetching the assistant ID:", error);
        }
      };
  
      fetchAssistantId();
    }, [assistantName]);
  
    useEffect(() => {
      const fetchData = async () => {
        if (!assistantId || !profileName) return;

        try {
          const response = await fetch(`https://capture-wetiwctgqq-nn.a.run.app/v1/firestore/get_full_page?assistant_id=${assistantId}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setUser(data);
  
          const correctProfile = data.profiles?.find(element => element.name === decodedProfileName);
          setProfile(correctProfile);
  
          if (data.background_color) {
            document.documentElement.style.setProperty('--background-color', data.background_color);
          }
        } catch (error) {
          console.error("There was an error fetching the user data:", error);
        }
      };
  
      if (assistantId) {
        fetchData();
      }
    }, [assistantId, profileName]);
  
    if (!user || !profile) {
      return <div>Wrong client/profile</div>;
    }
  
    return (
      <div className="app-container">
        <div className="content-container">
          <UserInfo user={user} profile={profile} />
          <div className="chat-interface-container">
            <ChatInterface assistantId={assistantId}/>
          </div>
        </div>
      </div>
    );
}

export default MainComponent;