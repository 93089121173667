import React, { useEffect, useState } from 'react';
import { DeepChat } from 'deep-chat-react';
import './ChatInterface.css';


const ChatInterface = ({ assistantId }) => {
  const [chatSettings, setChatSettings] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!assistantId) return;

      try {
        const response = await fetch(`https://capture-wetiwctgqq-nn.a.run.app/v1/firestore/get_scraping_document?document_id=${assistantId}&request_url=${window.location.origin}&specific_url=${window.location}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setChatSettings(data);
      } catch (error) {
        console.error("There was an error fetching the user data:", error);
      }
    };

    fetchData();
  }, [assistantId]);

  if (!chatSettings) {
    return <div>Waiting for Chat Settings...</div>;
  }

  const myUUID = crypto.randomUUID();
  const threadId = `thread_${myUUID}`;

  const handler = async (body, signals) => {
    try {
      const response = await fetch(`https://capture-wetiwctgqq-nn.a.run.app/v1/chat/langchain_chat?authorization=${process.env.REACT_APP_SECRET_KEY}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          assistant_id: assistantId,
          thread_id: threadId,
          message: body.messages[0].text,
          source: {
            device: 'Smart Card',
            url: window.location.href
          }
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      signals.onResponse({ text: data.response }); // Adjust to the actual response structure
    } catch (e) {
      console.error("There was an error:", e);
      signals.onResponse({ error: 'There was an error from the server' }); // Handle error appropriately
    }
  };

  const chatStyle = {
    borderRadius: '10px',
    borderColor: '#e4e4e4',
    background: 'linear-gradient(90deg, rgb(239, 242, 247) 0%, 7.60286%, rgb(237, 240, 249) 15.2057%, 20.7513%, rgb(235, 239, 248) 26.297%, 27.6386%, rgb(235, 239, 248) 28.9803%, 38.2826%, rgb(231, 237, 249) 47.585%, 48.1216%, rgb(230, 236, 250) 48.6583%, 53.1306%, rgb(228, 236, 249) 57.6029%, 61.5385%, rgb(227, 234, 250) 65.4741%, 68.7835%, rgb(222, 234, 250) 72.093%, 75.7603%, rgb(219, 230, 248) 79.4275%, 82.8265%, rgb(216, 229, 248) 86.2254%, 87.8354%, rgb(213, 228, 249) 89.4454%, 91.8605%, rgb(210, 226, 249) 94.2755%, 95.4383%, rgb(209, 225, 248) 96.6011%, 98.3005%, rgb(208, 224, 247) 100%)',
    width: '100%',
    height: '100%',
  };

  const textInputProps = {
    styles: {
      container: {
        borderRadius: '20px',
        border: 'unset',
        width: '78%',
        marginLeft: '-15px',
        boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)',
      },
      text: { padding: '10px', paddingLeft: '15px', paddingRight: '34px' },
    },
    placeholder: { text: 'Envoyer un message...', style: { color: '#606060' } },
  };

  const messageStyles = {
    default: {
      shared: {
        bubble: {
          backgroundColor: 'unset',

          marginTop: '10px',
          marginBottom: '10px',
          // boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)',
        },
      },
      init: {
        bubble: {
          background: '#0063b5'// 'linear-gradient(130deg, #2870EA 20%, #1B4AEF 77.5%)',
        },
      },
      user: {
        bubble: {
          background: '#0063b5'// 'linear-gradient(130deg, #2870EA 20%, #1B4AEF 77.5%)',
        },
      },
      ai: { bubble: { background: 'linear-gradient(90deg, rgb(239, 242, 247) 0%, 7.60286%, rgb(237, 240, 249) 15.2057%, 20.7513%, rgb(235, 239, 248) 26.297%, 27.6386%, rgb(235, 239, 248) 28.9803%, 38.2826%, rgb(231, 237, 249) 47.585%, 48.1216%, rgb(230, 236, 250) 48.6583%, 53.1306%, rgb(228, 236, 249) 57.6029%, 61.5385%, rgb(227, 234, 250) 65.4741%, 68.7835%, rgb(222, 234, 250) 72.093%, 75.7603%, rgb(219, 230, 248) 79.4275%, 82.8265%, rgb(216, 229, 248) 86.2254%, 87.8354%, rgb(213, 228, 249) 89.4454%, 91.8605%, rgb(210, 226, 249) 94.2755%, 95.4383%, rgb(209, 225, 248) 96.6011%, 98.3005%, rgb(208, 224, 247) 100%)',
        boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)',
       } },
    },
  };

  const microphoneProps = {
    button: {
      default: {
        container: { default: { bottom: '1em', right: '0.6em', borderRadius: '20px', width: '1.9em', height: '1.9em' } },
        svg: { styles: { default: { bottom: '0.35em', left: '0.35em' } } },
      },
      position: 'inside-right',
    },
  };

  const submitButtonStyles = {
    position: 'outside-right',
    submit: {
      container: {
        default: { bottom: '0.8em', borderRadius: '25px', padding: '6px 5px 4px', backgroundColor: 'unset' },
        hover: { backgroundColor: '#b0deff4f' },
        click: { backgroundColor: '#b0deffb5' },
      },
      svg: {
        content: '<?xml version="1.0" encoding="utf-8"?> <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m21.426 11.095-17-8A.999.999 0 0 0 3.03 4.242L4.969 12 3.03 19.758a.998.998 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81zM5.481 18.197l.839-3.357L12 12 6.32 9.16l-.839-3.357L18.651 12l-13.17 6.197z"/></svg>',
        styles: {
          default: {
            width: '1.5em',
            filter: 'brightness(0) saturate(100%) invert(10%) sepia(86%) saturate(6044%) hue-rotate(205deg) brightness(100%) contrast(100%)',
          },
        },
      },
    },
    loading: {
      svg: {
        styles: {
          default: {
            filter: 'brightness(0) saturate(100%) invert(72%) sepia(0%) saturate(3044%) hue-rotate(322deg) brightness(100%) contrast(96%)',
          },
        },
      },
    },
    stop: {
      container: { hover: { backgroundColor: '#ededed94' } },
      svg: {
        styles: {
          default: {
            filter: 'brightness(0) saturate(100%) invert(72%) sepia(0%) saturate(3044%) hue-rotate(322deg) brightness(100%) contrast(96%)',
          },
        },
      },
    },
  };

  const initialMessages = [
    { text: chatSettings.introductions.french, role: 'ai' },
    { text: chatSettings.urls[0].init_question, role: 'ai' },
  ];

  const introMessageContent = `
    <div style="text-align: center; background: transparent; padding: 20px; border: none; box-shadow: none;">
      <h2 style="margin-bottom: 8px; font-size: 24px; color: #333;">${chatSettings.chatbot_names.french}</h2>
      <p style="font-size: 15px; line-height: 20px; color: #666;">
      ${chatSettings.slogans.french}
      </p>
    </div>
  `

  return (
    <div className="chat-interface">
      <div className="chat-container">
        <DeepChat
          style={chatStyle}
          textInput={textInputProps}
          messageStyles={messageStyles}
          submitButtonStyles={submitButtonStyles}
          initialMessages={initialMessages}
          request={{
            handler: handler,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            }
          }}
          introMessage={{ 
            html: introMessageContent, 
            role: 'init' }}
        />
      </div>
    </div>
  );
};

export default ChatInterface;