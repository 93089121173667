import React from 'react';
import './UserInfo.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faInstagram,
  faLinkedin,
  faGoogle
} from "@fortawesome/free-brands-svg-icons";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";



import { faGlobe } from "@fortawesome/free-solid-svg-icons";

const UserInfo = ({ user, profile }) => {
  const handleCalendlyClick = (event) => {
    event.preventDefault();
    if (window.Calendly) {
      window.Calendly.initPopupWidget({ url: profile.calendly_url });
    }
  };

  if (user.button_color) {
    document.documentElement.style.setProperty('--button-color', user.button_color);
  }

  return (
    <div className="user-info">
      <img 
        src={user.logo_url} 
        alt="Company Logo" 
        className="company-logo" 
      />
      <hr />
      <div className="user-details">
        <img 
          src={profile.picture} 
          alt="User" 
          className="user-photo" 
        />
        {/* <h4 className="user-name">{profile.name}</h4> */}
        <p className="user-title">{profile.title}</p>
      </div>
      <h3>{user.assistant_title_fr}</h3>
      {/* <div className="user-contact-info">
            <h4 className="user-contact">
               <FaPhone /> 514-377-1703
            </h4>
            <h4 className="user-contact">
            <MdEmail /> ctremblay@s3r.ca
            </h4>
          
        </div> */}
        <div className="user-contact-info">
          <h4 className="user-name">{profile.name}</h4>
          {profile.phone && (
            <p className="user-contact">
              <FaPhone /> {profile.phone}
            </p>
          )}
          {profile.email && (
            <p className="user-contact">
              <MdEmail /> {profile.email}
            </p>
          )}
        </div>
      <br></br>
      
      <p dangerouslySetInnerHTML={{ __html: user.intro_msg_fr}}></p>
      <br></br>
      <p dangerouslySetInnerHTML={{ __html: user.core_msg_fr }}></p>
      <br></br>
      <a href="#" className="calendly-button" onClick={handleCalendlyClick}>
        {user.button_txt_fr}
      </a>
      
      <br></br>
      <div className="social-container">
        {user.platforms?.youtube && (
          <a href={user.platforms.youtube} className="youtube social" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} size="2x" />
          </a>
        )}
        {user.platforms?.facebook && (
          <a href={user.platforms.facebook} className="facebook social" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
        )}
        {user.platforms?.instagram && (
          <a href={user.platforms.instagram} className="instagram social" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
        )}
        {profile.linkedin_url && (
          <a href={profile.linkedin_url} className="linkedin social" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
        )}
        {user.platforms?.maps && (
          <a href={user.platforms.maps} className="maps social" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGoogle} size="2x" />
          </a>
        )}
        {user.platforms?.website && (
          <a href={user.platforms.website} className="website social" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGlobe} size="2x" />
          </a>
        )}
      </div>
    </div>
  );
};

export default UserInfo;